import React, { Component } from 'react'
import Landing from '../../../../components/case-studies/Landing'
import AboutProject from '../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../components/case-studies/PagesShowcase'
import ProjectShortDescription from '../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../components/case-studies/ColorShowcase'
import Footer from '../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../components/reveal'
import ProjectImageLightBox from '../../../../components/case-studies/ProjectImageLightBox'

class Supro extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter

    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Supro'}
          description={'Prípadová štúdia'}
          text={'Pre Supro sme vytvorili profesionálny moderný e-shop spojený s ICO kryptomeny Supro a mobilnou aplikáciou. Súčasťou projektu bola aj tvorba názvu brandu, logo a branding.'}
          img={data.landingImage}
          what_we_done={[
            { text: 'ux & web design' },
            { text: 'kódovanie front-endu (javascript, react)' },
            { text: 'programovanie (php)' },
            { text: 'mobilná aplikácia', link: '/nase-prace/aplikacie/supreme' },
            { text: 'tvorba názvu' },
            { text: 'logo & branding', link: '/nase-prace/grafika/logo-branding/supro-branding' }
          ]} />

        <AboutProject
          text={'Spolupráca s Supro prebiehala nasledovne:'}
          list={[
            {
              title: 'Ako to prebiehalo',
              text: 'Poďme sa spolu pozrieť na jednotlivé etapy vývoja projektu. Každý jeden projekt a klient je individuálny, podľa toho sa líši aj rozsah a detail prác na projekte. O momentálnej fáze tvorby je klient vždy informovaný a poskytuje nám svoj feedback.',
            },
            {
              title: 'Zadanie',
              text: 'Klient nám poskytol zadanie, ktoré sme si spolu odkonzultovali a spracovali do projektovej dokumentácie, ktorou sa následne riadil celý projekt. Niektorí klienti majú vlastnú špecifikáciu, iným ju spracujeme my na základe požiadaviek. Pri rozsiahlejších projektoch sa navrhuje celá architektúra, rozpisujú sa detailne všetky funkcie a kreslia sa use-case modely.',
            },
            {
              title: 'Riešenie',
              text: 'Od začiatku prác na projekte sa vyčlení menší tím, ktorý sa venuje práve danému projektu. Snažíme sa pracovať non-stop, aby už po krátkej dobe videl klient nejakú ukážku alebo prototyp a videli sme, či ideme správnou cestou. Ďalej projekt konzultujeme a posielame updaty, až kým sa nedopracujeme k výsledku, s ktorým sme aj my aj klient spokojný.',
            },
            {
              title: 'Výsledok',
              text: 'Na konci developmentu posielame klientovi beta verziu, kde si vie skontrolovať celý design a všetky funkcionality. Na tejto verzii rovnako testujeme aj my a optimalizujeme posledné detaily pred spustením projektu live.',
            }
          ]}
        />

        <ProjectShortDescription
          title={'Firemná identita'}
          description={'Pochopenie projektu, jeho zámeru a odborný prístup sú úspechom k plnohodnotnej firemnej identite.'}
          text={'Po konzultáciach s klientom sme pripravili zrozumiteľné firemné princípy, ktoré by mal nový projekt prezentovať nie len designom, ale aj textami, fotkami a inými komunikačno-vizuálnymi kanálmi.'} />

        <Reveal>
          <div className={'container p-0'}>
            <Img className={'mb-5'} alt={this.constructor.name} fluid={data.corporateIdentity.childImageSharp.fluid} />
          </div>
        </Reveal>

        <Reveal>
          <div className={'container p-0 mb-4'}>
            <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#7ecef4', tintColorCode: '#98d8f6', nameColor: '#FFFFFF', name: 'Light sky blue' },
          { code: '#0a5fa4', tintColorCode: '#3b7fb6', nameColor: '#FFFFFF', name: 'Medium Persian blue' },
          { code: '#decdcb', tintColorCode: '#e5d7d5', nameColor: '#FFFFFF', name: 'Timberwolf' },
        ]} />

        <PagesShowcase
          title={'UX & Moderný web design'}
          description={'Nová profesionálna tvár Vášho webu'}
          text={'Nový design stránky Supro zahŕňa všetko, čo by mala profesionálna stránka spĺňať. Jednoduchosť, krása a jedinečnosť sú len jednou časťou, ale tou hlavnou je samotný obsah stránky, ktorý musí byť stredobodom pre uživateľa, aby vypovedal to, čo je potrebné.'}
          projectDetail={'/nase-prace/webstranky/eshopy/supro'}
          pages={[
            {
              name: 'Hlavná stránka',
              image: data.pageOne,
            },
            {
              name: 'Registrácia',
              image: data.pageTwo,
            },
            {
              name: 'Nákup',
              image: data.pageThree,
            },
            {
              name: 'Platba',
              image: data.pageFour,
            },
            {
              name: 'Potvrdenie',
              image: data.pageFive,
            },
            {
              name: 'Peňaženka',
              image: data.pageSix,
            },
          ]} />
        {/*  <ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}

        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="letenky" />
          </div>
        </Reveal>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Biutimi'}
          nextProjectUrl={'/nase-prace/pripadove-studie/aplikacie/biutimi/'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/supro/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
  landingImage: file(relativePath: { eq: "case-studies/supro_landing.png" }) {
    childImageSharp {
      fluid( maxWidth: 1600) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
    childImageSharp {
      fixed( width: 250) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
    pageOne: file(relativePath: { eq: "case-studies/supro_v2.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTwo: file(relativePath: { eq: "case-studies/supro_registracia.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageThree: file(relativePath: { eq: "case-studies/supro_1_.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFour: file(relativePath: { eq: "case-studies/supro_s2_platobne_metody.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFive: file(relativePath: { eq: "case-studies/supro_s3_konfirmacia.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageSix: file(relativePath: { eq: "case-studies/supro.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
  logos: file(relativePath: { eq: "case-studies/supro_logos.png" }) {
    childImageSharp {
      fluid( maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  corporateIdentity: file(relativePath: { eq: "case-studies/supro_corporate_identity.png" }) {
    childImageSharp {
      fluid( maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`

export default Supro
